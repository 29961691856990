/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-05-25",
    versionChannel: "nightly",
    buildDate: "2024-05-25T00:06:11.903Z",
    commitHash: "aefe56a8b61aa99716406382bbb425e23e83a751",
};
